import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tablink'];

  connect() {
    if (this.hasTablinkTarget) {
      const tabId = this.tablinkTarget.dataset.tabsTabIdParam;

      const tabContent = document.getElementById(tabId);

      if (!!tabContent) {
        this.hideAllTabContents();
        this.tablinkTarget.classList.add('active-tab-link');
        tabContent.classList.remove('hidden');
        tabContent.classList.add('flex');
      }
    }
  }

  open({ params, currentTarget }) {
    const tabId = params?.tabId;

    const tabContent = document.getElementById(tabId);

    if (!!tabContent) {
      this.hideAllTabContents();

      this.tablinkTargets.forEach((tablinkTarget) => {
        tablinkTarget.classList.remove('active-tab-link');
      });

      currentTarget.classList.add('active-tab-link');
      tabContent.classList.remove('hidden');
      tabContent.classList.add('flex');
    }
  }

  hideAllTabContents() {
    const tabContents = document.querySelectorAll('.tabcontent');

    tabContents.forEach((tabContent) => {
      tabContent.classList.remove('flex');
      tabContent.classList.add('hidden');
    });
  }
}
