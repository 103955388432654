import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="last-element"
export default class extends Controller {
  static values = { class: String };

  connect() {
    this.element.lastElementChild.classList.add(this.classValue);
  }
}
