import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="appbar"
export default class extends Controller {
  static targets = ['homeMenu'];

  toggle() {
    this.homeMenuTarget.classList.toggle('hidden');
  }
}
