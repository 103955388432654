import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'inputs'];

  connect() {
    if (this.hasContainerTarget) {
      const nextContainer =
        this.containerTargets[this.containerTargets.length - 1];
      const nextInputs = this.inputsTargets[this.inputsTargets.length - 1];

      nextContainer.innerHTML = '';

      nextContainer.appendChild(nextInputs);
    }
  }
}
