import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['rateId', 'select', 'submit'];

  initialize() {
    let submitDisabled = true;
    this.selectTargets.forEach((select) => {
      if (select.dataset.rateIdParam === this.rateIdTarget.value) {
        select.innerText = 'Selected';
        select.classList.toggle('selected-dark');
        select.classList.toggle('selected-text-white');
        submitDisabled = false;
      }
    });

    if (submitDisabled) {
      this.submitTarget.classList.add('disabled');
    }

    this.submitTarget.disabled = submitDisabled;
  }

  select(event) {
    event.preventDefault();
    this.selectTargets.forEach((select) => {
      if (select == event.target) {
        select.innerText = 'Selected';
        select.classList.toggle('selected-dark');
        select.classList.toggle('selected-text-white');
        return;
      }

      select.innerText = 'Select';
      select.classList.remove('selected-dark', 'selected-text-white');
    });

    if (this.rateIdTarget.value === event.params.id) {
      this.rateIdTarget.value = null;
      this.submitTarget.disabled = true;
      this.submitTarget.classList.add('disabled');
    } else {
      this.rateIdTarget.value = event.params.id;
      this.submitTarget.disabled = false;
      this.submitTarget.classList.remove('disabled');
    }
  }
}
