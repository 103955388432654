import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ['sidebarMenu'];

  toggle() {
    this.sidebarMenuTarget.classList.toggle('-left-300');
  }
}
