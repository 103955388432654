import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['conditionalQuestions', 'dependentQuestions'];

  toggleDependentQuestions() {
    const existingCoverage =
      document.querySelector('form').elements[
        'carrier_questionnaire_form[conditional_question_attributes][answer]'
      ].value;

    if (existingCoverage === '1') {
      this.dependentQuestionsTarget.hidden = false;
    } else {
      this.dependentQuestionsTarget.hidden = true;

      const dependentInputs = [...this.dependentQuestionsTarget.children];

      dependentInputs.forEach((input) => {
        input.firstChild.value = '';
      });
    }
  }
}
