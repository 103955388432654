import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ['navigationMenu'];

  toggle() {
    this.navigationMenuTarget.classList.toggle('left-full');
  }
}
