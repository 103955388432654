import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tile', 'checkbox', 'coverageFor', 'coverageType'];

  select() {
    this.checkboxTarget.classList.toggle('bg-foamy-sea-500');
    this.checkboxTarget.classList.toggle('text-white');
    this.tileTarget.classList.toggle('border-foamy-sea-500');
    this.toggleHidden(this.coverageForTarget);
  }

  toggleHidden(element) {
    element.hidden = !element.hidden;

    if (element.hidden) {
      this.coverageTypeTarget.value = '';
    } else {
      const options = this.coverageTypeTarget.options;

      let mapping = {};

      [...options].map((option) => {
        if (!!option.value) {
          mapping[option.innerHTML] = option.value;
        }
      });

      this.coverageTypeTarget.value = mapping['me'];
    }
  }

  changeCoverageType(event) {
    event.stopPropagation();
  }
}
