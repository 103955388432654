import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['paymentType', 'bankAccountFields', 'creditCardFields'];

  togglePaymentType() {
    const selectedPaymentType = Array.from(
      document.querySelector('form').elements['application_form[payment_type]']
    ).filter((node) => node.checked)[0].value;

    this.bankAccountFieldsTarget.hidden = selectedPaymentType === 'CreditCard';
    this.creditCardFieldsTarget.hidden = selectedPaymentType === 'BankAccount';
  }
}
