import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ['notification'];

  hideMessage() {
    this.notificationTarget.classList.add('hidden');
  }

  connect() {
    setTimeout(() => {
      this.hideMessage();
    }, 5000);
  }

  dismiss(event) {
    event.preventDefault();
    this.hideMessage();
  }
}
